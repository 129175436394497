import React from "react";
import _get from "lodash.get";
import { graphql } from "gatsby";

import { PageSection, PageWrapper } from "app/components/DetailPageList";

export const SUBPAGE_QUERY = graphql`
  query subpageQuery($id: String) {
    entry: craftSitePageSiteDetailPageEntry(id: { eq: $id }) {
      id
      title
      updatedAt: dateUpdated
      ...SiteDetailPage
    }
  }
`;

const SubPagePreview = ({ data }) => (
  <PageWrapper>
    <PageSection
      pages={[_get(data, "entry")]}
      sectionTitle={_get(data, "entry.title")}
    />
  </PageWrapper>
);

export default SubPagePreview;
